import { DEFAULT_ACCOUNT_FILTER } from "src/resources/accounts/constants"
import { DEFAULT_TAG_FILTER } from "src/resources/tags/constants"
import { DEFAULT_YEAR_FILTER } from "src/resources/years/constants"

export const PUBLICATION_STATUSES = {
  published: "published",
  unpublished: "unpublished"
}

export const ALLOW_ATTENDEES_TO_EXPORT_MODES = {
  all: "all",
  members: "members"
}

export const TRANSCRIPT_STATUSES = {
  uploaded: "uploaded",
  inProgress: "inProgress",
  complete: "complete",
  failed: "failed"
}

export const DEFAULT_FILTERS = {
  accountFilter: DEFAULT_ACCOUNT_FILTER,
  tagFilter: DEFAULT_TAG_FILTER,
  yearFilter: DEFAULT_YEAR_FILTER
}
